<!--
 * @Date: 2021-05-07 17:20:18
 * @LastEditors: frank
 * @LastEditTime: 2021-05-07 21:33:44
 * @FilePath: /shop_frontend/src/views/pageDecoration/goodsList.vue
 * @Description: Description
-->
<template>
  <section class="goodsList">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
     
     
      <a-form-model-item label="当前排序">
        <a-input v-model="form.index" class="w220" />
      </a-form-model-item>
     
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit"> 保存 </a-button>
          <a-button type="danger" @click="onDelete" style="margin-left: 10px;"> 删除 </a-button>
      </a-form-model-item>
    </a-form-model>
  </section>
</template>

<script>
export default {
 name: 'goodsList',
  data() {
    return {
      form: {
        index: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {},
      visible: false,
    };
  },
  props: {
    info: {
      type: Array,
    },
  },
  components: {
  },
  computed: {
   
  },
  watch: {
    info: {
      handler: function (newVal) {
        console.log(newVal, "-------======-");

        this.form = Object.assign({}, this.form, newVal);
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
   
  
    onDelete () {
      this.$axios.delete('/goods_modules/' + this.form.id + '/').then(() => {
        this.$emit('refreshDel')
      })
    },
    onSubmit() {
      console.log(this.form, "form");
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$axios
            .put("/goods_modules/" + this.form.id + "/", this.form)
            .then((res) => {
              console.log(res);
              this.$message.success("操作成功");
              this.$emit("refresh");
            });
          //   alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  
  },
}
</script>

<style scoped lang="less">

</style>
